<script setup>
import { useRouter } from 'vue-router'
import VModal from '@/components/ui/modal/VModal'
import { touchBarDelete } from '@/utils/touchbar'
import VTable from '@/components/ui/table/VTable'
import useVModal from '@/use/Base/useVModal'
import VSearch from '@/components/ui/form/Search/VSearch'
import { onMounted, defineProps, provide, reactive, inject } from 'vue'
import { useFilter } from '@/use/Base/useFilter'
import AdminParametersForm from '@/components/views/Admin/AdminParametersForm'
import VFilter from '@/components/ui/modal/filter/VFilter'
import VSelect from '@/components/ui/form/VSelect'
import VLink from '@/components/ui/buttons/VLink'
import { PARAMETERS_CONTROLLER } from '@/utils/consts'

defineProps({
  title: {
    type: String,
    required: false
  }
})

const router = useRouter()
const { showVModal, openVModal, closeVModal } = useVModal()
const { input, onSubmit, onReset } = useFilter(filter)
const parametersController = inject(PARAMETERS_CONTROLLER)
const headRow = [
  {
    name: 'Краткое наименование',
    value: 'short_name',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Полное наименование',
    value: 'full_name',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Тип доп. параметра',
    value: 'type_name',
    filter: true
  },
  {
    name: 'Департамент',
    value: 'department_name',
    filter: true
  },
  {
    name: 'Группа критериев',
    value: 'category_name',
    filter: true
  }
]
const filterForm = reactive({
  group: null,
  type: null,
  department: null
})
const listForFilter = [
  { type: 'Department', key: 'department' },
  { type: 'Group', key: 'group' },
  { type: 'Type', key: 'type' }
]
const manual = reactive({
  type: [],
  department: [],
  group: []
})

const getResponse = async () => {
  manual.group = await parametersController.getCategory()
  manual.department = await parametersController.getDepartment()
  manual.type = await parametersController.getType()
}

onMounted(getResponse)

const transformObject = (data) => {
  data.forEach(item => {
    item.category_name = item.category.name
    item.department_name = item.department.name
    item.type_name = item.type?.name
  })

  return data
}

function filter () {
  const filterList = []

  listForFilter.forEach(item => {
    if (filterForm[item.key]) {
      item.value = filterForm[item.key]
      filterList.push(item)
    }
  })

  return filterList
}

provide('manual', manual)

const handlerLink = (row) => {
  router.push({ name: 'admin-parameter', params: { id: row.item.id } })
}

</script>

<template>
  <VLink
    v-if="$route.params.id"
    @click="$router.push({ name: 'admin-parameters' })"
    class="mb-40"
  >
    К справочнику
  </VLink>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div
    v-if="!$route.params.id"
  >

      <div class="block__control">
        <div class="block__field">
          <VSearch
            name="searchUsersAdmin"
            placeholder="Поиск"
            class-input="input-border-all input-padding-25 input-height-48"
            v-model="input"
          />

          <VFilter
            @onFilter="onSubmit"
            @onReset="onReset"
            v-if="!$route.path.includes('journal')"
          >
            <template v-slot:default>
              <VSelect
                name="departmentFilter"
                id="departmentFilter"
                v-model="filterForm.department"
                placeholder="Департамент"
                :options="manual.department"
              />

              <VSelect
                name="typeFilter"
                id="typeFilter"
                v-model="filterForm.type"
                placeholder="Тип"
                :options="manual.type"
              />

              <VSelect
                name="groupFilter"
                id="groupFilter"
                v-model="filterForm.group"
                placeholder="Группа критериев"
                :options="manual.group"
              />
            </template>
          </VFilter>
        </div>

        <button
          class="btn btn-primary btn-bg-green"
          @click="openVModal"
        >
          Создать
        </button>
      </div>

    <VTable
      :max-width="false"
      :head-row="headRow"
      :transformObject="transformObject"
      :touch-bar-list="touchBarDelete"
      url-set-rows="/registers/parameter"
      url-action="/registers/parameter/action"
      @link="handlerLink"
    />

    <teleport to="body">
      <VModal
        v-if="showVModal"
        modal-inner-class="width-820"
        @close="closeVModal"
      >
        <h3 class="modal__title mb-40">Создать доп. параметр</h3>
        <AdminParametersForm
          :closeModal="closeVModal"
        />
      </VModal>
    </teleport>
  </div>

  <router-view />
</template>
